import styled from '@emotion/styled'
import { AnimatedTitle } from 'app/components/Common/Animation/AnimatedTitle'
import { FadeInUp } from 'app/components/Common/Animation/FadeInUp'
import { Close } from 'app/components/Common/Close'
import { Gallery } from 'app/components/Common/Gallery'
import { Image, Props as ImageProps } from 'app/components/Common/Image'
import { Line } from 'app/components/Common/Line'
import { FlexBox } from 'app/components/Layout/FlexBox'
import { Media, MediaContextProvider, mediaStyle } from 'app/theme/media'
import useLockedBody from 'app/utils/useLockedBody'
import { useVocabularyData } from 'app/utils/vocabulary'
import { uniqBy } from 'lodash'
import React, { memo, useEffect, useState } from 'react'
import { LazyLoadComponent } from 'react-lazy-load-image-component'

import { Filter } from './Filter'

export interface CategoryProps {
  thumbnails?: ImageProps[]
  title: string
}

export interface Props {
  categories: CategoryProps[]
  languageCode: string
  pretitle?: string
  title?: string
}

export const GalleryCategories = memo(function GalleryCategories({
  categories,
  languageCode,
  pretitle,
  title,
}: Props) {
  if (!categories) {
    return null
  }

  const [_locked, setLocked] = useLockedBody()
  const [filteredCategory, setFilteredCategory] = useState('all')
  const [modalGalleryStatus, setModalGalleryStatus] = useState(false)
  const [clickedSlide, setClickedSlide] = useState<any>(null)

  let counter = 0
  let images: any = []

  const categoriesImages = categories.map((item: any) =>
    item.images.map((e: any) => images.push(e)),
  )

  useEffect(() => {
    if (modalGalleryStatus) {
      document.addEventListener('keydown', function (e) {
        switch (e.keyCode) {
          case 27:
            setLocked(false)
            setModalGalleryStatus(false)
            setClickedSlide(0)
            break
        }
      })
    }
  }, [modalGalleryStatus])

  return (
    <Container>
      <style type="text/css">{mediaStyle}</style>
      <MediaContextProvider>
        {pretitle ? (
          <FadeInUp>
            <Line />
            <Pretitle>{pretitle}</Pretitle>
          </FadeInUp>
        ) : null}
        {title ? <Title text={title} /> : null}

        {categories.length > 1 ? (
          <FadeInUp>
            <Filters dial={5} row wrap>
              <Filter
                active={filteredCategory === 'all'}
                category="all"
                title={useVocabularyData('all', languageCode)}
                onClick={() => {
                  setFilteredCategory('all')
                }}
              />
              {uniqBy(categories, 'title').map((item, index) => {
                const category = item.title
                  .replace(/[^a-zA-Z]/g, '')
                  .toLocaleLowerCase()
                return category ? (
                  <Filter
                    active={filteredCategory === category}
                    category={category}
                    key={index}
                    onClick={(e) => {
                      setFilteredCategory(
                        e.target.getAttribute('data-category'),
                      )
                    }}
                    title={item.title}
                  />
                ) : undefined
              })}
            </Filters>
          </FadeInUp>
        ) : null}

        <Media lessThan="desktopSmall">
          <Wrapper>
            {uniqBy(categories, 'title').map((list, index) => {
              const category = list.title
                .replace(/[^a-zA-Z]/g, '')
                .toLocaleLowerCase()
              if (!list.thumbnails) {
                return null
              }
              return (
                <React.Fragment key={index}>
                  <Category>{list.title}</Category>
                  {list.thumbnails.map((item, index) => {
                    counter = counter + 1
                    return (
                      <Thumb
                        className={
                          filteredCategory === category ||
                            filteredCategory === 'all'
                            ? 'visible'
                            : undefined
                        }
                        data-counter={counter}
                        key={index}
                        onClick={(e) => {
                          if (!modalGalleryStatus) {
                            setLocked(true)
                            setModalGalleryStatus(true)
                          } else {
                            setLocked(false)
                            setModalGalleryStatus(false)
                          }
                          const realClickedSlide =
                            Number(
                              e.currentTarget.getAttribute('data-counter'),
                            ) - 1
                          setClickedSlide(realClickedSlide)
                          setTimeout(() => {
                            setClickedSlide(null)
                          }, 100)
                        }}
                      >
                        <LazyLoadComponent>
                          <Image {...item} />
                        </LazyLoadComponent>
                      </Thumb>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </Wrapper>
        </Media>

        <Media greaterThanOrEqual="desktopSmall">
          <Wrapper>
            {uniqBy(categories, 'title').map((list, index) => {
              const category = list.title
                .replace(/[^a-zA-Z]/g, '')
                .toLocaleLowerCase()
              if (!list.thumbnails) {
                return null
              }
              return (
                <React.Fragment key={index}>
                  {list.thumbnails.map((item, index) => {
                    counter = counter + 1
                    return (
                      <Thumb
                        className={
                          filteredCategory === category ||
                            filteredCategory === 'all'
                            ? 'visible'
                            : undefined
                        }
                        data-counter={counter}
                        key={index}
                        onClick={(e) => {
                          if (!modalGalleryStatus) {
                            setLocked(true)
                            setModalGalleryStatus(true)
                          } else {
                            setLocked(false)
                            setModalGalleryStatus(false)
                          }
                          const realClickedSlide =
                            Number(
                              e.currentTarget.getAttribute('data-counter'),
                            ) - 1
                          setClickedSlide(realClickedSlide)
                          setTimeout(() => {
                            setClickedSlide(null)
                          }, 100)
                        }}
                      >
                        <LazyLoadComponent>
                          <Image {...item} />
                        </LazyLoadComponent>
                      </Thumb>
                    )
                  })}
                </React.Fragment>
              )
            })}
          </Wrapper>
        </Media>

        <Modal className={modalGalleryStatus ? ' open' : undefined}>
          <Close
            onClick={() => {
              setLocked(false)
              setModalGalleryStatus(false)
              setClickedSlide(0)
            }}
          />
          <Gallery
            clickedSlide={clickedSlide}
            images={images}
            isOpen={modalGalleryStatus}
          />
        </Modal>
      </MediaContextProvider>
    </Container>
  )
})

const Container = styled.section`
  padding: 10vw;
  text-align: center;

  @media (max-width: 991px) {
    padding: 70px 30px;
    margin-top: 70px;
  }
`

const Pretitle = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: middle;
  margin-left: 0.83vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 0.69vw;
  letter-spacing: 0.2em;
  text-transform: uppercase;

  @media (max-width: 1439px) {
    font-size: 10px;
    margin-left: 12px;
  }
`

const Title = styled(AnimatedTitle)`
  margin: 2.08vw 0 4.16vw;
  color: ${({ theme }) => theme.colors.variants.neutralDark1};
  font-family: ${({ theme }) => theme.fontFamily.heading};
  font-weight: bold;
  font-size: 4.16vw;
  line-height: 1.133;

  @media (max-width: 991px) {
    font-size: 28px;
  }
`

const Filters = styled(FlexBox)`
  margin: 4.16vw 0;

  @media (max-width: 1199px) {
    display: none;
  }
`
const Wrapper = styled.div`
  display: grid;
  grid-gap: 4.16vw;
  grid-template-columns: repeat(4, 1fr);
  grid-auto-rows: 12.639vw 9.111vh 12.639vw;
  grid-auto-flow: dense;

  > div {
    &:nth-of-type(4n + 1),
    &:nth-of-type(8n + 3),
    &:nth-of-type(8n + 6) {
      grid-row: span 2;
    }
  }

  @media (max-width: 1199px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    grid-gap: 30px;
    margin-top: 30px;
  }
`

const Category = styled.div`
  width: 100%;
  margin-bottom: 0.625rem;
  color: ${({ theme }) => theme.colors.variants.neutralDark2};
  font-family: ${({ theme }) => theme.fontFamily.paragraph};
  font-weight: 500;
  font-size: 10px;
  letter-spacing: 0.2em;
  text-align: center;
  text-transform: uppercase;
`

const Thumb = styled.div`
  display: none;
  cursor: pointer;
  height: 100%;
  border-radius: 0.69vw;
  overflow: hidden;
  position: relative;
  &:hover {
    img {
      transform: scale(1.07);
    }
  }

  &.visible {
    display: block;
  }

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 0.25s ease-in-out;
  }

  @media (max-width: 1199px) {
    aspect-ratio: 1.33;
    height: auto;
    flex: 0 calc(50% - 15px);
    border-radius: 6px;
  }
`

const Modal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  visibility: hidden;
  transition: 0.15s ease-out;
  z-index: -1;

  &.open {
    opacity: 1;
    visibility: visible;
    z-index: 9999;
  }
`
